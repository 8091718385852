import React from "react"
import { Layout } from "../components/layout"
import Seo from "../components/seo"
import { Controller, Scene } from 'react-scrollmagic'
import { BrowserView } from 'react-device-detect';

import { MailLink } from "../components/MailLink"
// import { MagLinks } from "../components/MagLinks"

const contactPage = () => {
  return (
    <Layout>
      <Seo 
        title="Contact"
        description="DESIGN STUDIO KのContactページです。お問い合わせ先を掲載しています。"
      />
        <div className="eyecatch">
          <div className="eyecatch__inner">
            <h1>
              <span>Contact</span>
            </h1>
          </div>
          <BrowserView>
            <Controller>
              <Scene
                classToggle={[".eyecatch__under", "enter"]} 
                triggerHook="onEnter"
                triggerElement=".enterContents"
                // offset={300}
              >
                <div className="eyecatch__under">
                  <div className={`mv__underRight`} />
                </div>
              </Scene>
            </Controller>
          </BrowserView>
        </div>
        <div className="contact__container enterContents">
          <section className="contact__containerSec">
            <div className="contact__containerSecBlock">
              <div className="contact__containerSecBlockTtl">
                <h2>Get in touch</h2>
              </div>
              <div className="contact__containerSecBlockTxt">
                <p>お仕事のご依頼、採用、メディア取材などのお問い合わせは下記メールアドレスより<br className="br__pc"/>お問い合わせ下さい。</p>
                <MailLink />
              </div>
            </div>
          </section>
        </div>
    </Layout>
  )
}

export default contactPage
